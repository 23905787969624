import React, { useMemo } from 'react';
import styled from 'styled-components';
import { graphql, navigate, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../components/ui/layout';
import Button from '../components/ui/button';
import Input from '../components/ui/input';
import screens from '../@style/screens';
import Footer from '../components/footer';
import Logo from '../images/logo.svg';
import DoubleArrow from '../images/double-arrow.svg';
import { categoriesUpper } from '../data/categories';
import SEO from '../components/seo';

const GetAllCategories = graphql`
  query GetAllCategories {
    allProducts {
      nodes {
        _source {
          product {
            ...ProductCategoryFields
          }
        }
      }
    }
  }
`;

const HomeWrapper = styled.div`
  width: 100%;
  min-height: 95vh;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  @media ${screens.laptop} {
    min-height: unset;
    padding-top: 50px;
    width: 90%;
    padding-bottom: 50px;
  }
  @media ${screens.standard} {
    padding-top: 90px;
  }
`;

const CategoriesWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & > div {
    margin: 12px 0;
    padding-right: 10px;
  }
  max-width: 80%;
  @media ${screens.laptopL} {
    padding: 50px 0;
    flex-direction: row;
    & > div {
      margin: 0;
      padding-right: 30px;
    }
  }
  @media ${screens.desktop} {
    max-width: 60%;
  }
`;

const HeaderImage = styled.img`
  margin: 30px 0 20px 0;
  height: 50vw;
  @media ${screens.laptop} {
    margin: 40px 0 40px 0;
    width: 55vh;
    height: 100%;
  }
  @media ${screens.laptopL} {
    margin: 40px 0 60px 0;
  }
`;

const HeaderSubTitle = styled.h1`
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  margin: 0 0 30px 0;
  @media ${screens.tablet} {
    max-width: 60vw;
  }
  @media ${screens.laptop} {
    font-size: 24px;
    max-width: 50vw;
  }
  @media ${screens.laptopL} {
    margin: 0 0 60px 0;
    max-width: 40vw;
  }
  $media ${screens.laptopXL} {
    max-width: 40vw;
  }
  @media ${screens.standard} {
    max-width: 40vw;
  }
  @media ${screens.desktop} {
    max-width: 25vw;
  }
`;

const FooterCopy = styled.h4`
  display: none;

  @media ${screens.laptop} {
    display: flex;
  }
  font-size: 18px;
  font-weight: 400;
  * {
    font-size: 18px;
    font-weight: 400;
  }
`;

const LowesLogo = styled(Logo)`
  width: 78.6px;
  height: 36.1px;
  @media ${screens.laptop} {
    width: 104.5px;
    height: 48px;
  }
  @media ${screens.standard} {
    width: 130.6px;
    height: 60px;
  }
`;

const FooterMobile = styled.div`
  width: 100vw;
  margin-top: auto;
  @media ${screens.laptop} {
    display: none;
  }
`;

const ChairImg = styled.img`
  display: none;
  z-index: -1;
  position: absolute;
  transform: rotate(0deg);
  right: 5%;
  top: 35%;
  height: 30%;
  @media ${screens.laptop} {
    display: block;
  }
`;

const ChairImgMobile = styled.img`
  z-index: -1;
  right: -25%;
  top: 60%;
  height: 30%;
  position: absolute;
  transform: rotate(10deg);
  @media ${screens.mobileL} {
    right: -15%;
  }
  @media ${screens.laptop} {
    display: none;
  }
`;

const LampImg = styled.img`
  display: none;
  z-index: -1;
  position: absolute;
  left: 10%;
  top: 17%;
  height: 30%;
  @media ${screens.laptop} {
    display: block;
  }
`;

const LampImgMobile = styled.img`
  z-index: -1;
  left: -27%;
  top: 0%;
  height: 30%;
  position: absolute;
  @media ${screens.mobileL} {
    left: -15%;
  }
  @media ${screens.laptop} {
    display: none;
  }
`;

const PlantImg = styled.img`
  display: none;
  overflow: hidden;
  z-index: -1;
  position: absolute;
  @media ${screens.laptop} {
    display: block;
    top: 55%;
    left: -3%;
    height: 23%;
  }
`;

const Wrapper = styled.div`
  display: relative;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
`;

const StyledLink = styled.a`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 15px;
  color: #00a3e0;
  cursor: pointer;
  @media ${screens.laptop} {
    font-size: 25px;
    margin-bottom: 50px;
  }
`;

const DoubleArrowWrapper = styled(DoubleArrow)`
  width: 12.72px;
  height: 13.32px;
  @media ${screens.laptop} {
    width: 21.26px;
    height: 20.5px;
  }
`;

const Home: React.FC<PageProps> = () => {
  const { allProducts } = useStaticQuery<Queries.GetAllCategoriesQuery>(GetAllCategories);

  const nonNullProducts = useMemo(
    () =>
      allProducts.nodes
        .filter((product) => product._source?.product?.merchandisingHierarchy?.divisionDescription)
        .map((productRaw) => {
          const division = productRaw._source?.product?.merchandisingHierarchy;
          if (division) {
            return `${division.divisionDescription}|${division.division}`;
          }
          return null;
        }),
    [allProducts.nodes]
  );

  const categoriesRaw = useMemo(() => [...new Set(nonNullProducts)], [nonNullProducts]);

  const categories = categoriesRaw.map((category) => {
    if (category) {
      const [name, id] = category.split('|');
      return { name: categoriesUpper[id] ?? name, id };
    }
    return {};
  });

  return (
    <Wrapper>
      <picture>
        <source srcSet="/images/chair.webp" type="image/webp" />
        <source srcSet="/images/chair.png" type="image/png" />
        <ChairImg src="/images/chair.webp" />
      </picture>
      <picture>
        <source srcSet="/images/lamp.webp" type="image/webp" />
        <source srcSet="/images/lamp.png" type="image/png" />
        <LampImg src="/images/lamp.webp" />
      </picture>
      <picture>
        <source srcSet="/images/plant.webp" type="image/webp" />
        <source srcSet="/images/plant.png" type="image/png" />
        <PlantImg src="/images/plant.png" />
      </picture>
      <Layout>
        <SEO />
        <HomeWrapper>
          <LowesLogo />
          {/* <picture>
            <source srcSet="/images/masthead.webp" type="image/webp" />
            <source srcSet="/images/masthead.png" type="image/png" /> */}
          <HeaderImage src="/images/masthead.png" />
          {/* </picture> */}
          <HeaderSubTitle>
            We're making our pro-grade 3D products free to all, to help builders create even more
            possibilities in the metaverse.
          </HeaderSubTitle>
          <Input
            id="search-input"
            placeholder="Search our 3D product catalog"
            onEnter={(phrase?: string) => navigate('/search', { state: { phrase: phrase } })}
            showBackground
          />
          <CategoriesWrapper>
            {categories.map((category, index) => (
              <Button
                key={index}
                onClick={() => navigate('/search', { state: { category: category.id } })}
                buttonStyle="text"
              >
                {category.name}
              </Button>
            ))}
            <picture>
            <source srcSet="/images/chair.webp" type="image/webp" />
            <source srcSet="/images/chair.png" type="image/png" />
            <ChairImgMobile src="/images/chair.webp" />
          </picture>
          <picture>
            <source srcSet="/images/lamp.webp" type="image/webp" />
            <source srcSet="/images/lamp.png" type="image/png" />
            <LampImgMobile src="/images/lamp.webp" />
          </picture>
          </CategoriesWrapper>
          {/*<StyledLink onClick={() => navigate('/collections')}>*/}
          {/*  <p>*/}
          {/*    LOWE’S EXCLUSIVE DECENTRALAND COLLECTIONS&nbsp;&nbsp;*/}
          {/*    <DoubleArrowWrapper />*/}
          {/*  </p>*/}
          {/*</StyledLink>*/}
          <FooterCopy>
            3D Content Powered by &nbsp;
            <Button
              link="https://www.lowesinnovationlabs.com/"
              buttonStyle="text"
              type="external"
              underline
            >
              Lowe’s Innovation Labs
            </Button>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Button
              link="https://www.lowes.com/l/about/privacy-and-security-statement"
              buttonStyle="text"
              type="external"
              underline
            >
              Privacy
            </Button>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Button link="/terms" buttonStyle="text" type="internal" underline>
              Terms of Use
            </Button>
          </FooterCopy>
          <FooterMobile>
            <Footer />
          </FooterMobile>
        </HomeWrapper>
      </Layout>
    </Wrapper>
  );
};

export default Home;
