import React from 'react';
import styled from 'styled-components';
import ResetSVG from '../../images/reset.svg';

interface Props {
  onClick: () => void;
}

// styles
const ResetWrapper = styled(ResetSVG)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  right: 15px;
`;

const ResetButton: React.FC<Props> = ({ onClick }) => <ResetWrapper onClick={() => onClick()} />;

export default ResetButton;
