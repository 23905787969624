const categoriesUpper: Record<string, string> = {
  '21': 'SEASONAL & OUTDOOR LIVING',
  '44': 'LIGHTING',
  '35': 'KITCHEN & BATH',
  '29': 'DECOR',
  '42': 'LAWN & GARDEN',
};

const categoriesCapitalized: Record<string, string> = {
  '21': 'Seasonal & Outdoor Living',
  '44': 'Lighting',
  '35': 'Kitchen & Bath',
  '29': 'Decor',
  '42': 'Lawn & Garden',
};


export { categoriesUpper, categoriesCapitalized };
