import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import screens from '../../@style/screens';
import ResetButton from './reset';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: string;
  children?: React.ReactNode;
  onEnter?: (word?: string) => void;
  showBackground?: boolean;
  handleOnChange?: (newValue: string) => void;
}

const StyledInput = styled.input<Props>`
  min-width: 50vw;
  width: 70vw;
  height: 54px;
  border: 2px solid #00a3e0;
  border-radius: 32px;
  font-size: 15px;
  font-weight: 400;
  padding: 0 10px 0 40px;
  color: white;
  align-self: center;
  background-image: url(/images/search.svg);
  background-position-x: 15px;
  background-position-y: center;
  background-size: 20px;
  background-repeat: no-repeat;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: scroll;
  background-origin: initial;
  background-clip: initial;
  background-color: ${({ showBackground }) => {
    if (showBackground) {
      return '#012169';
    }
    return 'transparent';
  }};
  &::placeholder {
    color: white;
  }

  @media ${screens.laptop} {
    height: 54px;
    width: 50vw;
    padding: 0 50px 0 60px;
    background-position-x: 25px;
    background-size: 24px;
    font-size: 20px;
  }

  @media ${screens.standard} {
    height: 60px;
    width: 50vw;
    padding: 0 80px 0 70px;
    background-position-x: 30px;
    background-size: 28px;
    font-size: 24px;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  align-self: center;
`;

const Input: React.FC<Props> = ({ defaultValue, children, onEnter, handleOnChange, ...rest }) => {
  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Row>
      <StyledInput
        {...rest}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
          rest?.onChange?.(event);
          if (handleOnChange) {
            handleOnChange(event.target.value);
          }
        }}
        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            onEnter?.(value);
          }
          rest?.onKeyUp?.(event);
        }}
      ></StyledInput>
      {value && (
        <ResetButton
          onClick={() => {
            setValue('');
            if (handleOnChange) {
              handleOnChange('');
            }
          }}
        />
      )}
    </Row>
  );
};

export default Input;
